import javascript from '../assets/tech/icon_javascript.svg';
import typescript from '../assets/tech/icon_typescript.svg';
import node from '../assets/tech/icon_node.svg';
import html5 from '../assets/tech/icon_html5.svg';
import css from '../assets/tech/icon_css.svg';
import sass from '../assets/tech/icon_sass.svg';
import github from '../assets/tech/icon_github.svg';
import react from '../assets/tech/icon_react.svg';
import postgesql from '../assets/tech/icon_postgresql.svg';
import graphql from '../assets/tech/icon_graphql.svg';
import prisma from '../assets/tech/icon_prisma.svg';
import nest from '../assets/tech/icon_nest.svg';
import docker from '../assets/tech/icon_docker.svg';

function Skills(props) {
  return (
    <div className="skills">
      <div className="container">
        <h2 className="skills-title">Tech Stack</h2>
        <p className="skills-text">
          This is my current Tech Stack, including languages, tools, and
          frameworks I use daily and in my project.
        </p>
        <div className="skills-items">
          <div className="skills-item">
            <img
              src={javascript}
              alt="JavaScript Logo"
            />
            <p>JavaScript</p>
          </div>
          <div className="skills-item">
            <img
              src={typescript}
              alt="TypeScript Logo"
            />
            <p>TypeScript</p>
          </div>
          <div className="skills-item">
            <img
              src={node}
              alt="Node.js Logo"
            />
            <p>Node</p>
          </div>
          <div className="skills-item">
            <img
              src={html5}
              alt="HTML5 Logo"
            />
            <p>HTML5</p>
          </div>
          <div className="skills-item">
            <img
              src={css}
              alt="CSS Logo"
            />
            <p>CSS</p>
          </div>
          <div className="skills-item">
            <img
              src={sass}
              alt="SASS Logo"
            />
            <p>SASS</p>
          </div>
          <div className="skills-item">
            <img
              src={github}
              alt="Git/Github Logo"
            />
            <p>Git/Github</p>
          </div>
          <div className="skills-item">
            <img
              src={react}
              alt="React.js Logo"
            />
            <p>React</p>
          </div>
          {/* <div className="skills-item">
            <img
              src={redux}
              alt="Redux Logo"
            />
            <p>Redux</p>
          </div> */}
          {/* <div className="skills-item">
            <img
              src={angular}
              alt="Angular Logo"
            />
            <p>Angular</p>
          </div> */}
          <div className="skills-item">
            <img
              src={postgesql}
              alt="PostgreSQL Logo"
            />
            <p>PostgreSQL</p>
          </div>
          <div className="skills-item">
            <img
              src={docker}
              alt="Docker Logo"
            />
            <p>Docker</p>
          </div>
          <div className="skills-item">
            <img
              src={prisma}
              alt="Prisma Logo"
            />
            <p>Prisma</p>
          </div>
          <div className="skills-item">
            <img
              src={graphql}
              alt="GraphQL Logo"
            />
            <p>GraphQL</p>
          </div>
          <div className="skills-item">
            <img
              src={nest}
              alt="Nestjs Logo"
            />
            <p>Nest</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
