import logo from '../assets/logo.svg';
import logoWhite from '../assets/logo_quer_weiss.svg';
import menuToggle from '../assets/icon_menu_toggle.svg';
import moon from '../assets/icon-moon.svg';
import React, { useEffect } from 'react';

function Header(props) {
  useEffect(() => {
    if (localStorage.getItem('darkmode')) {
      document.body.classList.add('dark');
    }
  }, []);

  const switchTheme = () => {
    if (!document.body.classList.contains('dark')) {
      document.body.classList.add('dark');
      localStorage.setItem('darkmode', 'on');
    } else {
      document.body.classList.remove('dark');
      localStorage.removeItem('darkmode');
    }
  };

  const showOverlay = () => {
    const overlay = document.getElementById('menu-overlay');
    overlay.style.display = 'flex';
  };
  
  const closeOverlay = () => {
    const overlay = document.getElementById('menu-overlay');
    overlay.style.display = 'none';
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header-wrapper">
          <a href="/#">
            <img
              className="header-wrapper-logo color"
              src={logo}
              alt="Wepanion Logo"
            />
            <img
              className="header-wrapper-logo white"
              src={logoWhite}
              alt="Wepanion Logo"
            />
          </a>
          <div className="header-nav">
            <nav>
              <a href="/">Home</a>
              <a href="#about">About me</a>
              <a href="#projects">Projects</a>
              <a href="/#contact">Contact</a>
            </nav>
          </div>
          <div className="header-right">
            <div className="header-right-language">
              <p className="header-right-language-german">DE</p>
              <p className="header-right-language-english">EN</p>
            </div>
            <div onClick={switchTheme} className="header-right-theme">
              <img src={moon} alt="Moon Icon" />
            </div>
            <a className="header-right-contact" href="#contact">
              Contact
            </a>
            <img
              id="header-right-menu"
              onClick={showOverlay}
              className="header-right-menu color"
              src={menuToggle}
              alt="Burger Menu Icon"
            />
          </div>
        </div>
      </div>
      <div id="menu-overlay" className="menu-overlay">
        <div
          id="menu-overlay-close"
          onClick={closeOverlay}
          className="menu-overlay-close"
        >
          &nbsp;
        </div>
        <nav>
          <a onClick={closeOverlay} href="/">Home</a>
          <a onClick={closeOverlay} href="#about">About me</a>
          <a onClick={closeOverlay} href="#projects">Projects</a>
          <a onClick={closeOverlay} href="/#contact">Contact</a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
