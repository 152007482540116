function Content() {
  return (
    <div className="content">
      <div className="container">
        <h1 className="content-heading">Impressum</h1>
        <div class="rich-text-block w-richtext">
          <p>
            Stefan Feldner e.U.
            <br />
            Wepanion
            <br />
            Innsbruckerstraße 11
            <br />
            6380 St. Johann in Tirol
            <br />
            <strong>(Keine Termine an diesem Standort)</strong>
          </p>
          <h3>Kontakt</h3>
          <p>
            Telefon: <a href="tel:0043676848780491">+43 (0) 676 848 780 491</a>
            <br />
            E-Mail: <a href="mailto:info@wepanion.com">info@wepanion.com</a>
          </p>
          <h3>Umsatzsteuer-ID</h3>
          <p>ATU77213515</p>
          <h3>Berufsbezeichnung und berufsrechtliche Regelungen</h3>
          <p>
            Berufszweig: IT-Dienstleistung
            <br />
            Gewerbeart: Dienstleistungen in der automatischen Datenverarbeitung
            und Informationstechnik
            <br />
            Mitglied bei: Wirtschaftskammer Tirol
            <br />
            Verliehen in: Österreich
          </p>
          <p>
            &zwj;<strong>Behörde gem. ECG (E-Commerce Gesetz)</strong>
            <br />
            Bezirkshauptmannschaft Kitzbühel
          </p>
          <p>Es gelten folgende berufsrechtliche Regelungen:</p>
          <h3>Redaktionell verantwortlich</h3>
          <p>
            Stefan Feldner e.U.
            <br />
            Wepanion
            <br />
            Innsbruckerstraße 11
            <br />
            6380 St. Johann in Tirol
          </p>
          <h3>Design, Programmierung, Webhosting</h3>
          <p>
            Stefan Feldner e.U. | Wepanion
            <br />
            <a
              href="https://www.wepanion.com/"
              target="_blank"
              rel="noreferrer"
            >
              wepanion.com
            </a>
          </p>
          <h3>EU-Streitschlichtung</h3>
          <p>
            Verbraucher haben die Möglichkeit, Beschwerden an die
            Online-Streitbeilegungsplattform der EU zu richten.
            <br />
            Die Europäische Kommission stellt hierfür folgende Plattform zur
            Verfügung:
            <br />
            &zwj;
            <a
              href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
              target="_blank"
              rel="noreferrer"
            >
              https://ec.europa.eu/consumers/odr/.
            </a>
          </p>
          <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
          <h3>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h3>
          <p>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
          <h3>Urheberrechtshinweis</h3>
          <p>
            Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
            unterliegen dem Urheberrecht. Falls notwendig, werden wir die
            unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich
            verfolgen.
          </p>
          <h3>Haftung für Links auf dieser Webseite</h3>
          <p>
            Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt
            wir nicht verantwortlich sind. Wenn Ihnen rechtswidrige Links auf
            unserer Webseite auffallen, bitte wir Sie uns zu kontaktieren, Sie
            finden die Kontaktdaten im Impressum.
          </p>
          <h3>Datenschutz</h3>
          <p>
            Alle Informationen bezüglich dem Datenschutz finden Sie{' '}
            <a href="/data-policy">hier</a>.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Content;
