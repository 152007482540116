function Contact(props) {
  return (
    <div className="contact" id="contact">
      <div className="container">
        <h2 className="contact-title">Let's talk!</h2>
        <p className="contact-text">
          You can find me on <a target="_blank" href="https://www.linkedin.com/in/stefan-feldner/" rel="noreferrer">LinkedIn</a>, <a target="_blank" href="https://github.com/stefanfeldner" rel="noreferrer">GitHub</a>, or contact me per <a href="mailto:stefanfeldner@gmail.com">E-Mail</a>. I'm always looking
          for new opportunities to grow and work with great people. You can send
          me an email here, and I will get back to you as soon as possible.
        </p>
        {/* <div className="contact-form">
          <form action="https://my.formsparrow.com/api/e964e3b81ebd7016949d1510c2158ba3" method="post">
            <input type="hidden" name="_replyto" value="%Email"></input>
            <input type="text" name="_honey" hidden></input>
            <div className="contact-form-entry">
              <label htmlFor="firstname" className="contact-form-label">First name *</label>
              <input required className="contact-form-input" name="firstname" id="firstname" type="text"></input>
            </div>
            <div className="contact-form-entry">
              <label htmlFor="lastname" className="contact-form-label">Last name</label>
              <input className="contact-form-input" name="lastname" id="lastname" type="text"></input>
            </div>
            <div className="contact-form-entry">
              <label htmlFor="email" className="contact-form-label">E-Mail *</label>
              <input required className="contact-form-input" name="email" id="email" type="email"></input>
            </div>
            <div className="contact-form-entry">
              <label htmlFor="number" className="contact-form-label">Phone number</label>
              <input className="contact-form-input" name="number" id="number" type="text"></input>
            </div>
            <div className="contact-form-entry">
              <label htmlFor="message" className="contact-form-label">Your message</label>
              <textarea  rows="6" cols="50" className="contact-form-input" name="message" id="message" type="text"></textarea>
            </div>
            <div className="contact-form-entry-check">
              <input required type="checkbox" id="data-policy" name="data-policy" value="Accepted"></input>
              <label htmlFor="message" className="contact-form-label">I have read and accepted the terms and data policy.</label>
            </div>
            <div className="contact-form-entry-submit">
              <input type="submit" value="Submit"></input>
            </div>
          </form>
        </div> */}
      </div>
    </div>
  );
}

export default Contact;
