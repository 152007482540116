function Work(props) {
  return (
    <div className='work'>
      <div className='container'>
        <h2 className='work-title'>Work Experience</h2>
        <div className='work-entry'>
          <h3 className='work-entry-title'>Full Stack Developer</h3>
          <div className='work-entry-subtitle'>ALPIN11 New Media GmbH - Jun 2022 - Present, Vienna</div>
          <ul>
            <li>
              ALPIN11 empowers B2B companies with holistic e-commerce solutions, delivering unique shopping experiences.
            </li>
            <li>
              I build a bridge between diverse software solutions, facilitating smooth data transfer.
            </li>
            <li>
              Developed frontend and backend applications using TypeScript.
            </li>
            <li>
              Ensured seamless integration of software components.
            </li>
          </ul>
        </div>
        <div className='work-entry'>
          <h3 className='work-entry-title'>Back End Developer</h3>
          <div className='work-entry-subtitle'>Wanderlust - 2022, Vienna</div>
          <ul>
            <li>
              Full-Stack Travel Planning Application with a companion app for
              mobile users on the go.
            </li>
            <li>
              I designed and implemented the data models, defined schemas, built
              a REST API serving the data, for the app and the website, and
              deployed the database to Heroku.
            </li>
          </ul>
        </div>
        <div className='work-entry'>
          <h3 className='work-entry-title'>Full Stack Developer</h3>
          <div className='work-entry-subtitle'>uspace - 2022, Vienna</div>
          <ul>
            <li>
              Full-Stack Web Application that lets users create their own
              personal spaces to share posts and update friends.
            </li>
            <li>
              I was responsible for building the whole application from scratch,
              developing the client and server, <br/>allowing users to log in using
              auth0, and designing and implementing the underlying database and
              data modeling.
            </li>
          </ul>
        </div>
        <div className='work-entry'>
          <h3 className='work-entry-title'>
            Freelance Frontend Developer / Owner
          </h3>
          <div className='work-entry-subtitle'>
            Wepanion e.U. - 2021 - 2022, Tyrol
          </div>
          <ul>
            <li>
              I worked with local businesses and created their web design,
              developed the websites, and managed them.
            </li>
            <li>
              Responsible for Web Development, Web Design, SEO, Social Media
              Marketing, Customer Acquisition, and Content.
            </li>
          </ul>
        </div>
        <div className='work-entry'>
          <h3 className='work-entry-title'>Frontend Developer</h3>
          <div className='work-entry-subtitle'>
            Futureweb GmbH - 2021 - 2021, Tyrol
          </div>
          <ul>
            <li>
              Responsible for the development of more than 100 websites using
              modern web technologies (HTML5, CSS/SASS, JS/jQuery, PHP, MySQL).
            </li>
            <li>Transfer of design files to fully functional websites.</li>
          </ul>
        </div>
        <div className='work-entry'>
          <h3 className='work-entry-title'>Junior Front End Developer</h3>
          <div className='work-entry-subtitle'>
            Futureweb GmbH - 2017 - 2021, Tyrolt
          </div>
          <ul>
            <li>
              Independent web development and maintenance of hundreds of
              websites.
            </li>
            <li>
              Helped customers with email, hosting, CMS, and website problems.
            </li>
            <li>Refactored older websites and improved the documentation. </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Work;
