import About from '../About';
import Work from '../Work';
import Projects from '../Projects';
import Skills from '../Skills';
import Contact from '../Contact';
import Hero from '../Hero'

function Home() {
  return (
    <div className="home">
      <Hero />
      <About />
      <Work />
      <Projects />
      <Skills />
      <Contact />
    </div>
  );
}

export default Home;
