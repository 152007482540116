function Hero(props) {
  return (
    <div className="container">
      <div className="hero-text">
        <h1>
          Hey! I'm Stefan, a Full-Stack Developer from Austria. I design and
          develop applications and websites using modern web technologies and
          tools!
        </h1>
      </div>
    </div>
  );
}

export default Hero;
