import profilePicture from '../assets/profile-picture.jpg';
import iconGithub from '../assets/tech/icon_github.svg';
import iconLinkedIn from '../assets/icon_linkedin.svg';

function About(props) {
  return (
    <div className='about' id='about'>
      <div className='container'>
        <div className='about-wrapper'>
          <div className='about-left'>
            <img
              className='about-left-image color'
              src={profilePicture}
              alt='Wepanion Logo'
            />
          </div>
          <div className='about-right'>
            <div className='about-right-title'>About me</div>
            <p className='about-right-text'>
              My name is Stefan Feldner. I'm a Software Developer from
              Austria, experienced in Full-Stack JavaScript Development. I'm now
              looking for exciting opportunities and a place to grow as a
              developer. Previously I worked as a self-employed web developer
              and helped local businesses with their websites. Before that, I
              worked for a local web development agency and worked on hundreds
              of websites, built them from scratch, supported customers, and
              added to the documentation.
            </p>
            <div className='about-right-subtitle'>What else?</div>
            <p className='about-right-text'>
              Besides coding, I enjoy traveling, gaming, and co-managing a small
              Discord community with about 140 people. I enjoy building personal
              projects and learning new technologies on the go. I'm currently
              based in Vienna and enjoy attending interesting meetups, tech
              talks, and events.
            </p>
            <div className='about-social'>
              <a
                href='https://www.linkedin.com/in/stefan-feldner'
                target='_blank'
                rel='noreferrer'
              >
                <img src={iconLinkedIn} alt='LinkedIn Icon' />
              </a>
              <a
                href='https://github.com/stefanfeldner'
                target='_blank'
                rel='noreferrer'
              >
                <img src={iconGithub} alt='Github Icon' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
