import logoWhite from '../assets/logo_quer_weiss.svg';
import iconPhone from '../assets/icon_phone.svg';
import iconEmail from '../assets/icon_mail.svg';
import iconGithub from '../assets/icon_github.svg';
import iconLinkedIn from '../assets/icon_linkedin.svg';
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-wrapper">
          <div className="footer-top">
            <img src={logoWhite} alt="Wepanion Logo" />
            <div className="footer-nav">
              <Link to="/imprint">Imprint</Link>
              <Link to="/data-policy">Data Policy</Link>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="footer-info">
              <div className="footer-info-mail">
                <img src={iconEmail} alt="Mail Icon" />
                <a href="mailto:info@wepanion.com">info@wepanion.com</a>
              </div>
              <div className="footer-info-phone">
                <img src={iconPhone} alt="Phone Icon" />
                <a href="tel:+43676848780491">+43 676 848 780 491</a>
              </div>
            </div>
            <div className="footer-social">
              <a
                href="https://www.linkedin.com/in/stefan-feldner"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iconLinkedIn} alt="LinkedIn Icon" />
              </a>
              <a
                href="https://github.com/stefanfeldner"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iconGithub} alt="Github Icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
