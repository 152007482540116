import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/pages/Home';
import Imprint from './components/pages/Imprint';
import DataPolicy from './components/pages/DataPolicy';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/data-policy" element={<DataPolicy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
