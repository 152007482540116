import projectWaschbaer from '../assets/projects/waschbaeren.png';
import projectElsenhof from '../assets/projects/elsenhof.png';
import projectChatbot from '../assets/projects/chatbot.png';
import projectQuiz from '../assets/projects/reactquiz.png';
import projectWepanion from '../assets/projects/wepanion.png';
import projectTravelApp from '../assets/projects/travel.png';
import projectUspace from '../assets/projects/uspace.png';
import projectWanderlust from '../assets/projects/wanderlust.png';
import projectReplayst from '../assets/projects/replayst.png';

function Projects(props) {
  const showEntries = () => {
    // find visibles and add visible to the next two entries
    const projectList = [...document.getElementsByClassName('project-entry')];
    const loadButton = document.getElementById('loadButton')
    let numOfVisibles = projectList.filter(elem => elem.classList.contains('visible')).length;
    for (let i = 0; i < numOfVisibles + 2; i++) {
      if (projectList[i] && !projectList[i].classList.contains('visible')) {
        projectList[i].classList.add('visible')
      }
    }
    // hide load button once every item is visible
    numOfVisibles = projectList.filter(elem => elem.classList.contains('visible')).length;
    if (numOfVisibles === projectList.length) {
      loadButton.style.display = 'none';
    }
  };
  return (
    <div className="projects" id="projects">
      <div className="container">
        <h2 className="projects-title">Projects</h2>
        <p className="projects-text">
          Here's a small list of my favorite websites and apps I created.
          <br />
          More about them is available on my GitHub.
        </p>
        <div className="projects-wrapper">
          <div className="project-entry visible">
            <a
              href="https://github.com/stefanfeldner/uspace"
              target="_blank"
              rel="noreferrer"
            >
              <div className="project-entry-title">uspace</div>
              <img
                src={projectUspace}
                alt="Project uspace"
              />
            </a>
          </div>
          <div className="project-entry visible">
            <a
              href="https://wanderlust-web.netlify.app/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="project-entry-title">Wanderlust</div>
              <img
                src={projectWanderlust}
                alt="Project Wanderlust"
              />
            </a>
          </div>
          <div className="project-entry visible">
            <a
              href="https://github.com/stefanfeldner/replayst-legacy"
              target="_blank"
              rel="noreferrer"
            >
              <div className="project-entry-title">Replayst</div>
              <img
                src={projectReplayst}
                alt="Project Replayst"
              />
            </a>
          </div>
          <div className="project-entry visible">
            <a
              href="https://www.textilreinigung-waschbaer.com/"
              target="_blank"
              rel="noreferrer" 
            >
              <div className="project-entry-title">Die Waschbären</div>
              <img
                src={projectWaschbaer}
                alt="Project Waschbären"
              />
            </a>
          </div>
          <div className="project-entry visible">
            <a
              href="https://www.elsenhof.at/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="project-entry-title">Elsenhof</div>
              <img
                className="about-left-image color"
                src={projectElsenhof}
                alt="Project Elsenhof"
              />
            </a>
          </div>
          <div className="project-entry visible">
            <a
              href="https://www.wepanion.com/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="project-entry-title">Wepanion</div>
              <img
                className="about-left-image color"
                src={projectWepanion}
                alt="Project Wepanion"
              />
            </a>
          </div>
          <div className="project-entry">
            <a
              href="https://sf-quizzical-react-app.netlify.app/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="project-entry-title">React Quiz</div>
              <img
                className="about-left-image color"
                src={projectQuiz}
                alt="Project React Quizz"
              />
            </a>
          </div>
          <div className="project-entry">
            <a
              href="https://github.com/stefanfeldner/capstone-travel-app"
              target="_blank"
              rel="noreferrer"
            >
              <div className="project-entry-title">Travel App</div>
              <img
                className="about-left-image color"
                src={projectTravelApp}
                alt="Project Travel Application"
              />
            </a>
          </div>
          <div className="project-entry">
            <a
              href="https://github.com/stefanfeldner/codeworks-chat-bot"
              target="_blank"
              rel="noreferrer"
            >
              <div className="project-entry-title">Codeworks Chatbot</div>
              <img
                className="about-left-image color"
                src={projectChatbot}
                alt="Project Chatbot"
              />
            </a>
          </div>
        </div>
        <div id="loadButton" className="project-bottom">
          <button onClick={showEntries} className="project-load">
            Load more
          </button>
        </div>
      </div>
    </div>
  );
}

export default Projects;
