function Content() {
  return (
    <div className="content">
      <div className="container">
        <h1 className="content-heading">Datenschutzerklärung</h1>
        <div class="simpletextelement">
          <div class="w-embed">
            <p>
              Wir freuen uns über Ihren Besuch auf unserer Website{' '}
              <a href="https://www.wepanion.com">https://www.wepanion.com</a>{' '}
              und dem damit verbundenen Interesse an unserem Unternehmen. Mit
              dem Ziel, Ihnen ein möglichst hohes Maß an Transparenz zu bieten,
              informieren wir Sie im Folgenden über die Art, den Umfang sowie
              den Zweck der Erhebung, Verarbeitung und Nutzung von
              personenbezogenen Daten, welche im Rahmen der Nutzung unserer
              Website anfallen. Die Datenschutzgrundverordnung (nachfolgend
              „DSGVO“ genannt) können Sie{' '}
              <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679">
                hier
              </a>{' '}
              als komplettes Dokument abrufen.
            </p>
            <p></p>
            <h2>Inhalt</h2>
            <p>
              <a href="#Anker_1">
                <strong>1. Begriffsdefinitionen</strong>
              </a>
              <br />
              <a href="#Anker_2">
                <strong>2. Verantwortlicher gemäß Artikel 4 Nr. 7 DSGVO</strong>
              </a>
              <br />
              <a href="#Anker_3">
                <strong>3. Rechtsgrundlage der Verarbeitung</strong>
              </a>
              <br />
              <a href="#Anker_4">
                <strong>4. Speicherung von Daten / Löschung von Daten</strong>
              </a>
              <br />
              <a href="#Anker_5">
                <strong>5. Weitergabe von personenbezogenen Daten</strong>
              </a>
              <br />
              <a href="#Anker_6">
                <strong>6. Erhebung von personenbezogenen Daten</strong>
              </a>
              <br />
              <a href="#Anker_6_1">
                6.1. Ausschließliche informatorische Nutzung unserer Website
              </a>
              <br />
              <a href="#Anker_6_2">6.2. Kontaktaufnahme per E-Mail</a>
              <br />
              <a href="#Anker_6_3">6.3. Kontaktformular</a>
              <br />
              <a href="#Anker_7">
                <strong>7. Webflow</strong>
              </a>
              <br />
              <a href="#Anker_7_1">7.1. Hosting</a>
              <br />
              <a href="#Anker_7_1_1">7.1.1. Fastly</a>
              <br />
              <a href="#Anker_7_1_2">7.1.1. Amazon CloudFront</a>
              <br />
              <a href="#Anker_7_2">7.2. Cloudflare</a>
              <br />
              <a href="#Anker_7_3">7.3. webflow.com</a>
              <br />
              <a href="#Anker_7_4">7.4. Rechtsgrundlage</a>
              <br />
              <a href="#Anker_8">
                <strong>8. Cookies</strong>
              </a>
              <br />
              <a href="#Anker_9">
                <strong>9. Userlike</strong>
              </a>
              <br />
              <a href="#Anker_10">
                <strong>10. Ihre Rechte</strong>
              </a>
              <br />
              <a href="#Anker_11">
                <strong>11. Widerspruchsrecht</strong>
              </a>
              <br />
              <a href="#Anker_12">
                <strong>12. Datensicherheit</strong>
              </a>
              <br />
            </p>
          </div>
          <div class="w-embed">
            <h2 id="Anker_1">1. Begriffsdefinitionen</h2>
            <p>
              Die nachfolgenden Begrifflichkeiten, die wir innerhalb unserer
              Datenschutzerklärung verwenden, werden innerhalb von Art. 4 DSGVO
              definiert. Hierbei handelt es sich nur um einen Auszug aus Art. 4
              DSGVO. Alle Begriffsbestimmungen können Sie in der DSGVO (
              <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679">
                hier abrufbar
              </a>
              ) einsehen.
            </p>
            <ul>
              <li>
                Personenbezogene Daten (Art. 4 Nr. 1 DSGVO)
                <br />
                Personenbezogene Daten sind alle Informationen, die sich auf
                eine identifizierte oder identifizierbare natürliche Person (im
                Folgenden „betroffene Person“) beziehen; als identifizierbar
                wird eine natürliche Person angesehen, die direkt oder indirekt,
                insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
                zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
                oder zu einem oder mehreren besonderen Merkmalen identifiziert
                werden kann, die Ausdruck der physischen, physiologischen,
                genetischen, psychischen, wirtschaftlichen, kulturellen oder
                sozialen Identität dieser natürlichen Person sind.
                <br />
              </li>
              <li>
                Verarbeiten (Art. 4 Nr. 2 DSGVO)
                <br />
                Unter Verarbeitung versteht man jeden mit oder ohne Hilfe
                automatisierter Verfahren ausgeführten Vorgang oder jede solche
                Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie
                das Erheben, das Erfassen, die Organisation, das Ordnen, die
                Speicherung, die Anpassung oder Veränderung, das Auslesen, das
                Abfragen, die Verwendung, die Offenlegung durch Übermittlung,
                Verbreitung oder eine andere Form der Bereitstellung, den
                Abgleich oder die Verknüpfung, die Einschränkung, das Löschen
                oder die Vernichtung.
                <br />
              </li>
              <li>
                Pseudonymisierung (Art. 4 Nr. 5 DSGVO)
                <br />
                Pseudonymisierung umfasst die Verarbeitung personenbezogener
                Daten in einer Weise, dass die personenbezogenen Daten ohne
                Hinzuziehung zusätzlicher Informationen nicht mehr einer
                spezifischen betroffenen Person zugeordnet werden können, sofern
                diese zusätzlichen Informationen gesondert aufbewahrt werden und
                technischen und organisatorischen Maßnahmen unterliegen, die
                gewährleisten, dass die personenbezogenen Daten nicht einer
                identifizierten oder identifizierbaren natürlichen Person
                zugewiesen werden.
                <br />
              </li>
              <li>
                Verantwortlicher (Art. 4 Nr. 7 DSGVO)
                <br />
                Der Verantwortliche ist die natürliche oder juristische Person,
                Behörde, Einrichtung oder andere Stelle, die allein oder
                gemeinsam mit anderen über die Zwecke und Mittel der
                Verarbeitung von personenbezogenen Daten entscheidet; sind die
                Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder
                das Recht der Mitgliedstaaten vorgegeben, so können der
                Verantwortliche beziehungsweise die bestimmten Kriterien seiner
                Benennung nach dem Unionsrecht oder dem Recht der
                Mitgliedstaaten vorgesehen werden. Ein Auftragsverarbeiter ist
                eine natürliche oder juristische Person, Behörde, Einrichtung
                oder andere Stelle, die personenbezogene Daten im Auftrag des
                Verantwortlichen verarbeitet.
                <br />
              </li>
              <li>
                Dritter (Art. 4 Nr. 10 DSGVO)
                <br />
                Ein Dritter ist eine natürliche oder juristische Person,
                Behörde, Einrichtung oder andere Stelle, außer der betroffenen
                Person, dem Verantwortlichen, dem Auftragsverarbeiter und den
                Personen, die unter der unmittelbaren Verantwortung des
                Verantwortlichen oder des Auftragsverarbeiters befugt sind, die
                personenbezogenen Daten zu verarbeiten.
                <br />
              </li>
              <li>
                Einwilligung (Art. 4 Nr. 11 DSGVO)
                <br />
                Unter einer Einwilligung der betroffenen Person versteht man
                jede freiwillig für den bestimmten Fall, in informierter Weise
                und unmissverständlich abgegebene Willensbekundung in Form einer
                Erklärung oder einer sonstigen eindeutigen bestätigenden
                Handlung, mit der die betroffene Person zu verstehen gibt, dass
                sie mit der Verarbeitung der sie betreffenden personenbezogenen
                Daten einverstanden ist.
                <br />
              </li>
              <li>
                Unternehmen (Art. 4 Nr. 18 DSGVO)
                <br />
                Unter einem Unternehmen versteht man eine natürliche oder
                juristische Person, die eine wirtschaftliche Tätigkeit ausübt,
                unabhängig von ihrer Rechtsform, einschließlich Vereinigungen
                oder Personengesellschaften, die regelmäßig einer
                wirtschaftlichen Tätigkeit nachgehen.
                <br />
              </li>
            </ul>
          </div>
          <div class="w-embed">
            <h2 id="Anker_2">2. Verantwortlicher gemäß Art. 4 Nr. 7 DSGVO</h2>
            <p>
              Stefan Feldner e.U.
              <br />
              Innsbruckerstraße 11
              <br />
              6380 St. Johann in Tirol
              <br />
              Telefon: +43 (0) 676 848 780 49
              <br />
              E-Mail: <a href="mailto:info@wepanion.com">info@wepanion.com</a>
              <br />
              Unser vollständiges Impressum können Sie hier abrufen:
              <br />
              <a href="https://www.wepanion.com/impressum">
                https://www.wepanion.com/impressum
              </a>
            </p>
            <p></p>
          </div>
          <div class="w-embed">
            <h2 id="Anker_3">3. Rechtsgrundlagen der Verarbeitung</h2>
            <p>
              Bei jeder innerhalb unserer Datenschutzerklärung beschriebenen
              Verarbeitung, teilen wir Ihnen die entsprechende Rechtsgrundlage,
              auf der die Verarbeitung vorgenommen wird, mit. Man unterscheidet
              hierbei in folgende Fallgruppen, bei denen eine Verarbeitung
              rechtmäßig ist: Sie haben uns eine Einwilligung zu der
              Verarbeitung der Sie betreffenden personenbezogenen Daten für
              einen oder mehrere bestimmte Zwecke erteilt (Art. 6 Abs. 1 S. 1
              lit. a DSGVO). Zwischen Ihnen und uns besteht ein Vertrag, für
              dessen Erfüllung die Verarbeitung erfolgt oder die Verarbeitung
              ist zur Durchführung vorvertraglicher Maßnahmen, die auf Anfrage
              Ihrerseits erfolgen, erforderlich (Art. 6 Abs. 1 S. 1 lit. b
              DSGVO). Die Erfüllung einer rechtlichen Verpflichtung, derer wir
              unterliegen, erfordert die Verarbeitung (Art. 6 Abs. 1 S. 1 lit. c
              DSGVO). Der Schutz lebenswichtiger Interessen Ihrerseits oder
              einer anderen natürlichen Person erfordern eine Verarbeitung (Art.
              6 Abs. 1 S. 1 lit. d DSGVO). Die Wahrnehmung einer uns
              übertragenen, im öffentlichen Interesse liegenden Aufgabe oder
              Ausübung öffentlicher Gewalt erfordern eine Verarbeitung (Art. 6
              Abs. 1 S. 1 lit. e DSGVO). Die Erforderlichkeit der Verarbeitung
              zur Wahrung unserer berechtigten Interessen oder eines Dritten,
              sofern nicht Ihre Interessen oder Grundrechte und Grundfreiheiten,
              die den Schutz personenbezogener Daten erfordern, überwiegen (Art.
              6 Abs. 1 S. 1 lit. f DSGVO).
            </p>
          </div>
          <div class="w-embed">
            <h2 id="Anker_4">4. Speicherung von Daten / Löschung von Daten</h2>
            <p>
              Innerhalb der in unserer Datenschutzerklärung beschriebenen
              Verarbeitungen, teilen wir Ihnen die entsprechenden Speicherdauer
              bzw. die Zeitpunkte der Löschung oder Sperrung von Daten mit. Wird
              keine ausdrückliche Speicherdauer definiert, erfolgt eine Löschung
              bzw. Sperrung der Daten, sobald der Zweck oder die Rechtsgrundlage
              für die Speicherung nicht mehr gegeben ist. Eine Speicherung kann
              über die definierten Zeiten hinaus erfolgen, wenn gesetzliche
              Vorschriften, denen wir unterliegen (z.B. § 147 AO, § 247 HGB)
              eine andere Speicherdauer vorsehen. Im Anschluss an die
              Speicherdauer werden die personenbezogenen Daten gelöscht bzw.
              gesperrt, es sei denn eine weitere Speicherung ist durch uns auf
              Grund einer Rechtsgrundlage erforderlich. Zudem ist eine
              Speicherung über die angegebene Zeit hinaus im Falle einer
              (etwaigen) Rechtsstreitigkeit mit Ihnen bzw. eines sonstigen
              Rechtsverfahrens möglich.
            </p>
          </div>
          <div class="w-embed">
            <h2 id="Anker_5">5. Weitergabe von personenbezogenen Daten</h2>
            <p>
              Wenn eine Weitergabe Ihrer personenbezogenen Daten erfolgt, werden
              Sie hierüber an der jeweiligen Stelle unserer Datenschutzerklärung
              entsprechend aufgeklärt. Wenn eine Weitergabe Ihrer
              personenbezogenen Daten außerhalb des Europäischen
              Wirtschaftsraumes und somit in sogenannte Drittländer erfolgt,
              werden Sie hierüber an der jeweiligen Stelle unserer
              Datenschutzerklärung entsprechend aufgeklärt. Grundsätzlich werden
              unsererseits personenbezogene Daten nur in Drittländer
              übermittelt, bei denen ein angemessenes Schutzniveau seitens der
              EU-Kommission bestätigt wurde oder wir eine Sicherstellung des
              sorgfältigen Umgangs mit den personenbezogenen Daten auf Grund
              vertraglicher Vereinbarungen oder anderer geeigneter Garantien
              gewährleisten können.
            </p>
            <p></p>
          </div>
          <div class="w-embed">
            <h2 id="Anker_6">6. Erhebung von personenbezogenen Daten</h2>
            <p>
              Nachfolgend werden wir Sie über die Erhebung von personenbezogenen
              Daten (wie zum Beispiel Name, E-Mail-Adresse, Anschrift oder
              Nutzerverhalten) informieren.
            </p>
            <p></p>
          </div>
          <div class="w-embed">
            <h3 id="Anker_6_1">
              6.1. Ausschließliche informatorische Nutzung unserer Website
            </h3>
            <p>
              Wenn Sie sich auf unserer Website weder registrieren (zum Beispiel
              in Form eines Newsletters) noch uns auf anderer Weise Daten
              übermitteln (zum Beispiel durch Nutzung eines Kontaktformulars),
              werden nur jene personenbezogenen Daten erhoben, die von Ihrem
              Browser an unseren Server übermittelt werden. Dies sind Daten, die
              für uns technisch erforderlich sind, um Ihnen die Website unter
              Gewährleistung einer sicheren und stabilen Anzeige zur Ansicht
              bereit zu stellen. Hierbei handelt es sich um folgende
              Informationen, die sich aus einer Logfile-Zeile ergeben:
            </p>
            <p></p>
            <p>
              Internetprotokoll-Adresse (IP-Adresse)
              <br />
              Uhrzeit und Datum des jeweiligen Zugriffs
              <br />
              Zeitzonendifferenz zur Greenwich Mean Time (GMT)
              <br />
              Die konkret aufgerufene Seite
              <br />
              Status des Zugriffs / Hypertext Transfer Protocol (http)
              <br />
              Datenmenge, die jeweils übertragen wurde
              <br />
              Website, von welcher der Zugriff auf unsere Website erfolgt
              (Referrer-URL)
              <br />
              Verwendeter Internet-Browser (inkl. Sprache und Version)
              <br />
              Verwendetes Betriebssystem
              <br />
            </p>
            <p></p>
          </div>
          <div class="w-embed">
            <p>
              Die Rechtsgrundlage zur Erhebung der aufgelisteten Daten ergibt
              sich aus Art. 6 Abs. 1 S. 1 lit. f DSGVO. Wir haben ein
              berechtigtes Interesse einen fehlerfreien Verbindungsaufbau und
              eine komfortable Nutzung unserer Website sicherzustellen sowie die
              Systemstabilität und -sicherheit zu analysieren und die Daten zu
              weiteren administrativen Zwecken zu verwenden.
            </p>
            <p></p>
            <h3 id="Anker_6_2">6.2. Kontaktaufnahme per E-Mail</h3>
            <p>
              Bei Kontaktaufnahme über die in Ziffer 2 vorhandene E-Mail-Adresse
              oder sonstiger E-Mail-Adressen unseres Unternehmens, die auf
              unserer Website publiziert sind, wird Ihre E-Mail-Adresse, sowie
              sonstige innerhalb Ihrer Mail vorhandenen Kontaktdaten (z.B. Ihr
              Name oder Ihre Telefonnummer) von uns gespeichert, um Ihre Anfrage
              zu bearbeiten. Diese Daten werden umgehend gelöscht, sobald ein
              weiteres Speichern nicht länger erforderlich ist. Sollten
              gesetzliche Aufbewahrungsfristen hinsichtlich der Daten gegeben
              sein, tritt anstelle der Löschung der Daten eine entsprechende
              Einschränkung der Verarbeitung. Die Rechtsgrundlage zur
              Verarbeitung der Daten ergibt sich je nach dem Grund des
              E-Mailversandes aus Art. 6 Abs. 1 S. 1 lit. b DSGVO oder aus Art.
              6 Abs. 1 S. 1 lit. f DSGVO, geschieht also entweder zur Abwicklung
              des mit Ihnen geschlossenen Vertrages und zur Erfüllung unserer
              (vor)vertraglichen Pflichten oder stützt sich auf unser
              berechtigtes Interesse mit Interessenten unserer Dienstleistung in
              Kontakt zu treten.
            </p>

            <h3 id="Anker_6_3">6.3. Kontaktformular</h3>
            <p>
              Bei Kontaktaufnahme durch das auf unserer Website vorhandene
              Kontaktformular werden die von Ihnen angegebene Kontaktdaten von
              uns gespeichert und verarbeitet, um Ihre Anfrage zu bearbeiten.
              Die Rechtsgrundlage zur Verarbeitung der Daten ergibt sich je nach
              dem Grund der Kontaktaufnahme aus Art. 6 Abs. 1 S. 1 lit. b DSGVO
              oder aus Art. 6 Abs. 1 S. 1 lit. f DSGVO, geschieht also entweder
              zur Abwicklung des mit Ihnen geschlossenen Vertrages und zur
              Erfüllung unserer (vor)vertraglichen Pflichten oder stützt sich
              auf unser berechtigtes Interesse mit Interessenten unserer
              Dienstleistung in Kontakt zu treten.
            </p>
          </div>
          <div class="w-embed">
            <h2 id="Anker_7">7. Webflow</h2>
            <p>
              Unsere Website wird von dem Unternehmen Webflow, Inc., 398 11th
              Street, 2nd Floor, San Francisco, CA 94103 (nachfolgend „Webflow“
              genannt) gehostet. Gleichzeitig stellt Webflow das
              Content-Management-System für unsere Website zur Verfügung. Wir
              haben mit dem Unternehmen einen Auftragsverarbeitungsvertrag
              abgeschlossen welcher die Standardvertragsklauseln für die
              Übermittlung personenbezogener Daten an Auftragsverarbeiter in
              Drittländern nach der Richtlinie 95/46/EG des Europäischen
              Parlaments und des Rates (hier in deutscher Sprache abrufbar)
              enthalten. Die globale Datenschutzerklärung von Webflow können Sie
              hier abrufen:
              <a href="https://webflow.com/legal/privacy">
                https://webflow.com/legal/privacy
              </a>
              . Die Datenschutzerklärung für die EU und sie Schweiz können sie
              hier abrufen:
              <a href="https://webflow.com/legal/eu-privacy-policy">
                https://webflow.com/legal/eu-privacy-policy
              </a>
            </p>
            <h3 id="Anker_7_1">7.1. Hosting</h3>
            <p>
              Webflow hostet unsere Website mittels der Content Delivery
              Networks der US-amerikanischen Unternehmen Fastly Inc. und Amazon
              Web Services, Inc. Bei einem Content Delivery Network handelt es
              sich um ein Netzwerk von räumlich verteilten, ggf. miteinander
              verbundenen Servern. Hierbei wird stets der Server verwendet, der
              sich am nächsten zum jeweiligen Nutzer der Website befindet. Das
              vorliegend genutzte CDN, beinhaltet Server in Nordamerika und
              Teilen von Europa. Mehr Informationen erhalten Sie auf der
              folgenden Seite von Webflow:
              <a href="https://webflow.com/blog/what-to-look-for-in-a-web-hosting-service ">
                https://webflow.com/blog/what-to-look-for-in-a-web-hosting-service
              </a>
            </p>
            <h4 id="Anker_7_1_1">7.1.1. Fastly</h4>
            <p>
              Webflow hostet unsere Website mittels des Content Delivery Network
              des US-amerikanischen Unternehmens Fastly Inc. 475, Brannan St.
              #300, San Francisco, CA 94107. Die Datenschutzerklärung des
              Unternehmens können Sie hier abrufen:
              <a href="https://www.fastly.com/privacy/.">
                https://www.fastly.com/privacy/
              </a>
            </p>
            <h4 id="Anker_7_1_2">7.1.2. Amazon CloudFront</h4>
            <p>
              Webflow hostet unsere Website mittels des Content Delivery Network
              des US-amerikanischen Unternehmens Amazon Web Services, Inc., 410
              Terry Avenue North, Seattle WA 98109. Das CDN nennt sich Amazon
              CloudFront. Das Impressum des Unternehmens können Sie hier
              abrufen:{' '}
              <a href="https://aws.amazon.com/de/impressum/?nc1=f_cc  ">
                https://aws.amazon.com/de/impressum/?nc1=f_cc
              </a>
            </p>
            <p>
              Die Datenschutzhinweise des Unternehmens können Sie hier abrufen:
              <a href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice_German_2020-08-15.pdf ">
                https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice_German_2020-08-15.pdf
              </a>
            </p>
            <h3 id="Anker_7_2">7.2. Cloudflare</h3>
            <p>
              Um eine Cross-Browser-Kompatibilität zu gewährleisten, sodass die
              moderne Funktionalität von Webflow-Seiten auch in älteren
              Browsern, die diese nicht nativ unterstützen, verfügbar ist, wird
              seitens Webflow JavaScript mittels des CDN Cloudflare eingebunden.
              Betreiber des CDN ist das Unternehmen Cloudflare, Inc., 101
              Townsend St., San Francisco, CA 94107. Die Datenschutzerklärung
              des Unternehmens können Sie hier abrufen:
              <a href="https://www.cloudflare.com/de-de/privacypolicy/">
                https://www.cloudflare.com/de-de/privacypolicy/
              </a>
            </p>
            <h3 id="Anker_7_3">7.3. webflow.com</h3>
            <p>
              Des Weiteren besteht eine Verbindung zu der Domain webflow.com.
              Diese Domain gehört zu dem Unternehmen Webflow. Dort werden Bilder
              und andere Assets gehostet, die in unsere Website eingebunden
              sind. Auch diese Domain des Unternehmen Webflow wird über die CDNs
              Fastly und Amazon CloudFront gehostet.
            </p>
            <h3 id="Anker_7_4">7.4. Rechtsgrundlage</h3>
            <p>
              Die Rechtsgrundlage für die Datenverarbeitung im Sinne des
              Vorstehenden liegt in Art. 6 Abs. 1 S. 1 lit. b DSGVO sowie in
              unserem berechtigten Interesse nach Art. 6 Abs. 1 S. 1 lit. f
              DSGVO, da die Leistungen von Webflow am besten unseren
              unternehmerischen Bedürfnissen entsprechen und wir unseren Kunden
              hierdurch eine höhere Website-Performance bieten können. Es findet
              in den Ziffer 7.1. – 7.3. zudem eine Übermittlung von Daten in die
              USA und somit in ein so genanntes unsicheres Drittland im Sinne
              der DSGVO statt. Die Datenübertragung ist gleichwohl zulässig. Die
              Rechtsgrundlage hierfür liegt in Art. 46 Abs.1, Abs.2 lit. c DSGVO
              (Standardsvertragsklauseln) bzw. in Art. 49 Abs. 1 lit. b DSGVO
              und somit in der Erfüllung eines Vertrags zwischen uns und Ihnen
              als Nutzer bzw. in der Notwendigkeit der Durchführung von
              vorvertraglichen Maßnahmen. Für die USA bestehen derzeit weder ein
              Angemessenheitsbeschlusses der EU noch sonst geeignete Garantien.
              Der Schutz Ihrer Daten kann im Zielland USA nicht gewährleistet
              werden. In den USA besteht aktuell kein der EU gleichwertiges
              Datenschutzniveau. Daher geht die Übermittlung mit entsprechenden
              Risiken einher. Insbesondere bestehen keine Garantien im Hinblick
              auf das Unterbleiben von Zugriffen auf Ihre übermittelten Daten
              durch staatliche Stellen.
            </p>
            <p>
              So kann es z.B. nicht ausgeschlossen werden, dass US-Behörden auf
              Grundlage von Abschnitt 702 des Foreign Intelligence Surveillance
              Act (kurz FISA; zu Deutsch etwa „Gesetz zur Überwachung in der
              Auslandsaufklärung“, ein Gesetz, das die Auslandsaufklärung und
              Spionageabwehr der Vereinigten Staaten regelt) auf Ihre Daten
              zugreifen. Wir weisen Sie in diesem Zusammenhang ausdrücklich
              darauf hin, dass Ihnen als EU-Bürger insoweit keine effektive
              Rechtsschutzmöglichkeit gegen die Verarbeitung Ihrer Daten durch
              US-Behörden auf Grundlage des FISA zur Verfügung steht.
            </p>
          </div>
          <div class="w-embed">
            <h2 id="Anker_8">8. Cookies</h2>
            <p>
              Wir verwenden auf unserer Website Cookies. Cookies sind kleine,
              browserspezifische Textdateien, die auf Ihrer Festplatte
              gespeichert werden. Hierdurch erhält die Stelle, die das jeweilige
              Cookie setzt, bestimmte Informationen, wodurch jedoch weder
              Programme ausgeführt noch Viren übertragen werden können. Man
              unterteilt Cookies in die folgenden Klassen:
              <br />
              Zunächst erfolgt die Unterscheidung danach, wer das jeweilige
              Cookie gesetzt hat (Webseitenbetreiber in Form von
              First-Party-Cookies oder Dritte in Form von Third-Party-Cookies).
              <br />
              Dann gibt es eine Unterscheidung bzgl. der Dauer der Speicherung.
              <br />
            </p>
            <ul>
              <li>
                Es existieren <strong>transiente Cookies</strong>, die mit
                Schließen des Browsers automatisch gelöscht werden, was vor
                allem sogenannte Session-Cookies betrifft, welche eine
                Session-ID speichern. Mit diesen Session-Cookies wird Ihr
                Rechner wiedererkannt, wenn Sie innerhalb einer Sitzung mit dem
                gleichen Browser nochmal unsere Website aufrufen. Wenn Sie den
                Browser schließen oder sich ausloggen, werden diese temporären
                Cookies gelöscht.
                <br />
              </li>
              <li>
                Daneben gibt es sog. <strong>persistente Cookies</strong>, die
                für einen längeren Zeitraum (bis zu zwei Jahren) gespeichert
                werden. Der Zeitraum bis zur Löschung unterscheidet sich jedoch
                von Cookie zu Cookie. Sie können eine Löschung dieser Cookies
                jederzeit manuell über die Einstellungen Ihres Browsers
                vornehmen.
                <br />
              </li>
              <li>
                Eine weitere Gruppe bilden die sog.{' '}
                <strong>Flash-Cookies</strong>. Hierbei handelt es sich um ein
                Flash-Player gebundenes Cookie, das die technischen Daten, die
                zur Wiedergabe von Video- oder Audio-Inhalten benötigt werden
                (z.B. Bildqualität oder Netzwerkgeschwindigkeit), speichert,
                wobei hierbei im Normalfall kein automatisches Ablaufdatum
                vorliegt und die Cookies die erforderlichen Daten unabhängig vom
                verwendeten Browser speichern. Manche Browser (z.B. Firefox)
                bieten die Möglichkeit, Flash-Cookies zusammen mit den anderen
                Cookies zu löschen.
                <br />
              </li>
            </ul>
            <p>
              Des Weiteren unterscheidet man Cookies auf Grund ihrer Funktion,
              was aus datenschutzrechtlicher Sicht am relevantesten ist.
              <br />
            </p>
            <ul>
              <li>
                <strong>Technical (essenzielle) Cookies</strong> sind Cookies,
                die notwendig sind, um grundlegende Funktionen der Website
                ausführen zu können (z.B. Speichern eines Produktes, das in den
                Warenkorb gelegt wurde).
                <br />
              </li>
              <li>
                <strong>Performance Cookies</strong> sammeln Informationen über
                die Nutzung der Website und hierbei auftretende Fehler. Hierbei
                handelt es sich um anonyme Informationen, die zur Verbesserung
                der Website eingesetzt werden.
                <br />
              </li>
              <li>
                Mittels <strong>Advertising Cookies</strong> oder{' '}
                <strong>Targeting Cookies</strong> ist es möglich, dem Nutzer
                der Website angepasste Werbung (auch von Drittanbietern)
                anzuzeigen und diese Anzeige hinsichtlich der Effektivität zu
                bestimmen.
                <br />
              </li>
              <li>
                Durch <strong>Sharing Cookies</strong> wird die Website mit
                anderen Diensten (z.B. Social-Media-Präsenzen) verbunden.
                <br />
              </li>
            </ul>
            <p>
              Wir setzen automatisiert lediglich Technical Cookies und somit
              Cookies, die für den Betrieb unserer Website essenziell sind, auf
              Grund unseres berechtigten Interesses i.S.d. Art. 6 Abs. 1 S. 1
              lit. f DSGVO ein, um unsere Website effektiv zu gestalten und
              stetig zu verbessern.
              <br />
              Wir weisen darauf hin, dass Sie die Speicherung von Cookies
              jederzeit durch eine entsprechende Einstellung Ihres Browsers
              unterbinden können. Weitere Informationen in diesem Zusammenhang
              haben wir hinsichtlich der gängigsten Browser nachfolgend
              zusammengestellt, weisen jedoch darauf hin, dass hierdurch die
              Funktionsfähigkeit unserer Website eingeschr\u00E4nkt werden kann.
            </p>
            <ul>
              <li>
                Mozilla Firefox:{' '}
                <a href="https://support.mozilla.org/de/kb/verbesserter-schutz-aktivitatenverfolgung-desktop">
                  https://support.mozilla.org/de/kb/verbesserter-schutz-aktivitatenverfolgung-desktop
                </a>
                <br />
              </li>
              <li>
                Microsoft Edge:{' '}
                <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">
                  https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
                </a>
                <br />
              </li>
              <li>
                Google Chrome:{' '}
                <a href="https://support.google.com/chrome/answer/95647">
                  https://support.google.com/chrome/answer/95647
                </a>
                <br />
              </li>
              <li>
                Opera:{' '}
                <a href="https://help.opera.com/de/latest/web-preferences/#cookies">
                  https://help.opera.com/de/latest/web-preferences/#cookies
                </a>
                <br />
              </li>
              <li>
                Safari:{' '}
                <a href="https://support.apple.com/de-de/guide/safari/sfri11471/mac">
                  https://support.apple.com/de-de/guide/safari/sfri11471/mac
                </a>
                <br />
              </li>
            </ul>
          </div>
          <div class="w-embed">
            <h2 id="Anker_9">9. Userlike</h2>
            <p>
              Wir nutzen den Chatbot des Unternehmens Userlike UG
              (haftungsbeschränkt), Probsteigasse 44-46, 50670 Köln, Impressum:{' '}
              <a href="https://www.userlike.com/de/imprint ">
                https://www.userlike.com/de/imprint{' '}
              </a>{' '}
              (nachfolgend „Userlike“ genannt). Die Datenschutzerklärung von
              Userlike kann hier abgerufen werden:{' '}
              <a href="https://www.userlike.com/de/terms#privacy-policy">
                https://www.userlike.com/de/terms#privacy-policy
              </a>
              . Wir haben einen entsprechenden Vertrag zur
              Auftragsdatenverarbeitung mit dem Unternehmen Userlike
              abgeschlossen. Das Tool Userlike setzt automatisiert Cookies. Wir
              haben die Einstellungen des Tools so angepasst, dass die Cookies
              nach 30 Tagen automatisiert gelöscht werden. Wir nutzen den
              Chatbot nur dann, wenn Sie uns über dieses Consent-Tool Ihre
              ausdrückliche Einwilligung hierzu erteilt haben (Art. 6 Abs. 1 S.
              1 lit. a DSGVO i.V.m. Art. 49 Abs. 1 lit. a DSGVO). Der Chatbot
              von Userlike wird über das CDN des US-amerikanischen Unternehmens
              Amazon Web Services, Inc., 410 Terry Avenue North, Seattle WA
              98109 gehostet. Das CDN nennt sich Amazon CloudFront. Das
              Impressum des Unternehmens können Sie hier abrufen:{' '}
              <a href="https://aws.amazon.com/de/impressum/?nc1=f_cc">
                https://aws.amazon.com/de/impressum/?nc1=f_cc
              </a>
              <br />
              Die Datenschutzhinweise des Unternehmens können Sie hier abrufen:
              <a href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice_German_2020-08-15.pdf ">
                https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice_German_2020-08-15.pdf
              </a>
              <br />
              Bei einem CDN (Content Delivery Network) handelt es sich um ein
              Netzwerk von räumlich verteilten, ggf. miteinander verbundenen
              Servern. Hierbei wird stets der Server verwendet, der sich am
              nächsten zum jeweiligen Nutzer der Website befindet. Es ist sehr
              wahrscheinlich, dass Ihre Daten zu Servern übertragen werden, die
              sich in Deutschland bzw. in der EU befinden, soweit Sie die Seite
              aus der EU heraus aufrufen.
              <br />
              <strong>
                Es kann jedoch nicht ausgeschlossen werden, dass Ihre Daten
                neben der EU ggf. auch in ein unsicheres Drittland (wie z.B.
                USA) übermittelt werden. Mit Ihrer Einwilligung werden, im
                Rahmen des hier verwendeten Dienstes, Ihre Daten also neben der
                EU ggf. auch in ein solches unsicheres Drittland (z.B. USA)
                übermittelt. Jedenfalls für die USA bestehen derzeit weder ein
                Angemessenheitsbeschlusses der EU noch sonst geeignete
                Garantien. Der Schutz Ihrer Daten kann im Zielland USA nicht
                gewährleistet werden. In den USA besteht aktuell kein der EU
                gleichwertiges Datenschutzniveau. Daher geht die Übermittlung
                mit entsprechenden Risiken einher. Insbesondere bestehen keine
                Garantien im Hinblick auf das Unterbleiben von Zugriffen auf
                Ihre übermittelten Daten durch staatliche Stellen. So kann es
                z.B. nicht ausgeschlossen werden, dass US-Behörden auf Grundlage
                von Abschnitt 702 des Foreign Intelligence Surveillance Act
                (kurz FISA; zu Deutsch etwa „Gesetz zur Überwachung in der
                Auslandsaufklärung“, ein Gesetz, das die Auslandsaufklärung und
                Spionageabwehr der Vereinigten Staaten regelt) auf Ihre Daten
                zugreifen. Wir weisen Sie in diesem Zusammenhang ausdrücklich
                darauf hin, dass Ihnen als EU-Bürger insoweit keine effektive
                Rechtsschutzmöglichkeit gegen die Verarbeitung Ihrer Daten durch
                US-Behörden auf Grundlage des FISA zur Verfügung steht. Wenn Sie
                Ihre Einwilligung erteilen, so tun Sie dies in Kenntnis dieser
                Risiken, die Sie dadurch auch bewusst in Kauf nehmen. Sie können
                Ihre Einwilligung jederzeit mittels der Cookie-Einstellungen
                widerrufen, in dem Sie nur essenzielle Cookies akzeptieren. Des
                Weiteren weisen wir darauf hin, dass Sie die Speicherung von
                Cookies jederzeit durch eine entsprechende Einstellung Ihres
                Browsers unterbinden können. Weitere Informationen in diesem
                Zusammenhang haben wir hinsichtlich der gängigsten Browser
                nachfolgend zusammengestellt, weisen Sie jedoch darauf hin, dass
                hierdurch die Funktionsfähigkeit unserer Website eingeschränkt
                werden kann.
              </strong>
            </p>
            <ul>
              <li>
                Mozilla Firefox:{' '}
                <a href="https://support.mozilla.org/de/kb/verbesserter-schutz-aktivitatenverfolgung-desktop">
                  https://support.mozilla.org/de/kb/verbesserter-schutz-aktivitatenverfolgung-desktop
                </a>
                <br />
              </li>
              <li>
                Microsoft Edge:{' '}
                <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">
                  https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
                </a>
                <br />
              </li>
              <li>
                Google Chrome:{' '}
                <a href="https://support.google.com/chrome/answer/95647">
                  https://support.google.com/chrome/answer/95647
                </a>
                <br />
              </li>
              <li>
                Opera:{' '}
                <a href="https://help.opera.com/de/latest/web-preferences/#cookies">
                  https://help.opera.com/de/latest/web-preferences/#cookies
                </a>
                <br />
              </li>
              <li>
                Safari:{' '}
                <a href="https://support.apple.com/de-de/guide/safari/sfri11471/mac">
                  https://support.apple.com/de-de/guide/safari/sfri11471/mac
                </a>
                <br />
              </li>
            </ul>
          </div>
          <div class="w-embed">
            <h2 id="Anker_10">10. Ihre Rechte</h2>
            <p>
              Nachfolgend klären wir Sie über Ihre Rechte nach der DSGVO auf.
              Die DSGVO können Sie{' '}
              <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679">
                hier
              </a>{' '}
              als komplettes Dokument abrufen.
            </p>
            <ul>
              <li>
                Auskunftsrecht nach Art. 15 Abs. 1 DSGVO
                <br />
                <p>
                  Sie haben das Recht von uns eine Bestätigung darüber zu
                  verlangen, ob Sie betreffende personenbezogene Daten von Ihnen
                  verarbeitet werden. Ist dies zu bejahen, haben Sie neben dem
                  Recht auf Auskunft über diese personenbezogenen Daten, ein
                  Recht auf Informationen über Verarbeitungszwecke, die
                  Kategorien der verarbeiteten personenbezogener Daten, die
                  Empfänger oder Kategorien von Empfängern, gegenüber denen Ihre
                  personenbezogenen Daten offengelegt wurden oder zukünftig
                  offengelegt werden (insbesondere bei Empfängern in
                  Drittländern oder bei internationalen Organisationen), die
                  Speicherdauer bzw. Kriterien für die Festlegung der
                  Speicherdauer, das Bestehen eines Berichtigungs- oder
                  Löschungsrechts der Sie betreffenden personenbezogenen Daten
                  oder dem Recht auf Einschränkung der Verarbeitung unsererseits
                  sowie über Bestehen eines Widerspruchsrechts gegen diese
                  Verarbeitung, das Bestehen eines Beschwerderechts bei einer
                  Aufsichtsbehörde, alle verfügbaren Informationen über die
                  Herkunft der Daten (für den Fall, dass diese nicht durch uns
                  erhoben wurden), das Bestehen einer automatisierten
                  Entscheidungsfindung einschließlich Profiling und ggf.
                  aussagekräftige Informationen über die involvierte Logik sowie
                  die Tragweite und die angestrebten Auswirkungen einer
                  derartigen Verarbeitung.
                  <br />
                </p>
              </li>
              <li>
                Recht auf Berichtigung nach Art. 16 DSGVO
                <br />
                <p>
                  Sie haben das Recht, von uns die unverzügliche Berichtigung
                  unrichtiger personenbezogener Daten sowie die
                  Vervollständigung unvollständiger personenbezogener Daten, die
                  Sie betreffen, zu verlangen.
                  <br />
                </p>
              </li>
              <li>
                Recht auf Löschung („Recht auf Vergessenwerden“) nach Art. 17
                Abs. 1 DSGVO
                <br />
                <p>
                  Sie haben das Recht von uns zu verlangen, dass wir die Sie
                  betreffenden personenbezogenen Daten unverzüglich löschen.
                  Dieses Recht besteht nach Art. 17 Abs. 3 DSGVO jedoch nicht,
                  wenn die Verarbeitung zur Ausübung des Rechts auf freie
                  Meinungsäußerung und Information, zur Erfüllung einer
                  rechtlichen Verpflichtung, aus Gründen des öffentlichen
                  Interesses im Bereich der öffentlichen Gesundheit, für im
                  öffentlichen Interesse liegende Archivzwecke oder zur
                  Geltendmachung, Ausübung oder Verteidigung von
                  Rechtsansprüchen erforderlich ist.
                  <br />
                </p>
              </li>
              <li>
                Recht auf Einschränkung der Verarbeitung nach Art. 18 Abs. 1
                DSGVO
                <br />
                <p>
                  Sie haben das Recht von uns die Einschränkung der Verarbeitung
                  Ihrer personenbezogenen Daten zu verlangen, wenn von Ihnen die
                  Richtigkeit Ihrer personenbezogenen Daten bestritten wird (die
                  Einschränkung gilt hierbei für die Dauer, die uns eine
                  Überprüfung der Richtigkeit ermöglicht), die Verarbeitung
                  Ihrer personenbezogenen Daten unrechtmäßig ist und Sie eine
                  Löschung ablehnen, wir Ihre personenbezogenen Daten für die
                  Verarbeitungszwecke nicht mehr benötigen, Sie diese jedoch
                  benötigen um Rechtsansprüche geltend zu machen, auszuüben oder
                  zu verteidigen oder Sie Widerspruch gegen die Verarbeitung
                  nach Art. 21 Abs. 1 DSGVO eingelegt haben (die Einschränkung
                  gilt hierbei, so lange noch nicht feststeht, ob unsere
                  berechtigten Gründe die Ihren überwiegen).
                  <br />
                </p>
              </li>
              <li>
                Recht auf Datenübertragbarkeit nach Art. 20 DSGVO
                <br />
                <p>
                  Sie haben das Recht, die Sie betreffenden personenbezogenen
                  Daten von uns in einem strukturierten, gängigen und
                  maschinenlesbaren Format zu erhalten sowie eine Übermittlung
                  an einen anderen Verantwortlichen ohne Behinderung
                  unsererseits vorzunehmen (bzw. eine direkte Übermittlung von
                  uns an einen anderen Verantwortlichen zu verlangen, sofern
                  dies technisch möglich ist), wenn die Verarbeitung durch uns
                  auf einer Einwilligung oder einem Vertrag beruhte oder
                  mithilfe automatisierter Verfahren erfolgt ist.
                  <br />
                </p>
              </li>
              <li>
                Recht auf Widerruf erteilter Einwilligungen nach Art. 7 Abs. 3
                DSGVO
                <br />
                <p>
                  Sie haben das Recht, eine einmal erteilte Einwilligung
                  jederzeit uns gegenüber mit Wirkung für die Zukunft zu
                  widerrufen, sodass die Datenverarbeitung, welche auf Grund der
                  Einwilligung erfolgte für die Zukunft nicht mehr fortgeführt
                  werden kann, jedoch die Rechtmäßigkeit der bis zu Ihrem
                  Widerruf erfolgten Verarbeitung hiervon nicht berührt wird.
                  <br />
                </p>
              </li>
              <li>
                Recht auf Beschwerde nach Art. 77 DSGVO
                <br />
                <p>
                  Sie haben unbeschadet eines anderweitigen
                  verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs, das
                  Recht, Beschwerde bei einer Aufsichtsbehörde einzulegen, wenn
                  Sie der Ansicht sind, dass die Verarbeitung der Sie
                  betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
                  In der Regel können Sie sich an die Aufsichtsbehörde Ihres
                  üblichen Aufenthaltsortes, Ihres Arbeitsplatzes oder dem Ort
                  des mutmaßlichen Verstoßes wenden. Weitere Informationen
                  hierzu erhalten Sie auf der{' '}
                  <a href="https://www.bfdi.bund.de/DE/Home/home_node.html">
                    Website des Bundesbeauftragten für den Datenschutz und die
                    Informationsfreiheit.
                  </a>
                  <br />
                </p>
              </li>
            </ul>
            <h2 id="Anker_11">11. Widerspruchsrecht</h2>
            <p>
              Sie haben neben den genannten Rechten, zudem das Recht jederzeit
              gegen die Verarbeitung Ihrer personenbezogenen Daten, welche auf
              Grund der Wahrnehmung einer Aufgabe, die im öffentlichen Interesse
              oder in Ausübung öffentlicher Gewalt (Art. 6 Abs. 1 S. 1 lit. e
              DSGVO) bzw. zur Wahrung von berechtigten Interessen unsererseits
              (Art. 6 Abs. 1 S. 1 lit. f DSGVO) erfolgt, Widerspruch mit Wirkung
              für die Zukunft einzulegen, sofern hierfür Gründe vorliegen,
              welche sich aus ihrer besonderen Situation ergeben. Im Falle des
              Widerspruchs wird keine weitere Verarbeitung der personenbezogenen
              Daten vorgenommen, sofern wir keine zwingenden schutzwürdigen
              Gründe für die Verarbeitung nachweisen können, die Ihre
              Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung
              dazu dient um Rechtsansprüche geltend zu machen, auszuüben oder zu
              verteidigen. Im Falle der Verarbeitung Ihrer personenbezogenen
              Daten für den Zweck der Direktwerbung bzw. des Profilings, sofern
              eine Verbindung zur Direktwerbung besteht, haben Sie ein
              generelles Recht auf Widerspruch, ohne dass Gründe, die sich aus
              Ihrer besonderen Situation ergeben, vorliegen müssen. Im Falle
              eines Widerspruchs stellen wir die Verarbeitung der
              personenbezogenen Daten zu diesen Zwecken sofort ein. Um von Ihrem
              Widerrufs- oder Widerspruchsrecht Gebrauch zu machen, genügt eine
              E-Mail an:
              <a href="mailto:info@wepanion.com">info@wepanion.com</a>
              <br />
            </p>

            <h2 id="Anker_12">12. Datensicherheit</h2>
            <p>
              Auf unserer Website wird das Verschlüsselungs- und
              Kommunikationsprotokoll TLS 1.2 (Transport Layer Security)
              eingesetzt. Durch das von uns verwendete und von einer
              Zertifizierungsstelle ausgestellte TLS-Zertifikat, ermöglichen wir
              einen verschlüsselten Datenaustausch zwischen Webbrowser und
              Webserver, wodurch sensible Daten nicht von Dritten ausgelesen
              werden können. Wir verwenden das Verfahren mit der jeweils
              höchsten Verschlüsselungsstufe, die Ihr Browser unterstützt, in
              der Regel wird es sich hierbei um eine 256-Bit Verschlüsselung
              handeln. Umso höher die Bit-Zahl, desto länger der Key und desto
              besser somit der Schutz vor Dritten.
            </p>

            <i>
              Diese Datenschutzerklärung wurde individuell für diese Website von
              der{' '}
              <a href="https://frame-for-business.de/">
                Frame for Business GmbH
              </a>{' '}
              in Kooperation mit der
              <a href="https://www.ra-schuetzle.de/">
                Schützle Rechtsanwaltsgesellschaft mbH
              </a>{' '}
              erstellt.
            </i>
          </div>
          <div class="w-embed">
            <h2>Analyse-Tools und Werbung</h2>
            <h3>Google Analytics</h3>
            <p>
              Diese Website nutzt Funktionen des Webanalysedienstes Google
              Analytics. Anbieter ist die Google Ireland Limited („Google“),
              Gordon House, Barrow Street, Dublin 4, Irland.
            </p>
            <p>
              Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten
              der Websitebesucher zu analysieren. Hierbei erhält der
              Websitebetreiber verschiedene Nutzungsdaten, wie z.&nbsp;B.
              Seitenaufrufe, Verweildauer, verwendete Betriebssysteme und
              Herkunft des Nutzers. Diese Daten werden von Google ggf. in einem
              Profil zusammengefasst, das dem jeweiligen Nutzer bzw. dessen
              Endgerät zugeordnet ist.
            </p>
            <p>
              Google Analytics verwendet Technologien, die die Wiedererkennung
              des Nutzers zum Zwecke der Analyse des Nutzerverhaltens
              ermöglichen (z.&nbsp;B. Cookies oder Device-Fingerprinting). Die
              von Google erfassten Informationen über die Benutzung dieser
              Website werden in der Regel an einen Server von Google in den USA
              übertragen und dort gespeichert.
            </p>
            <p>
              Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
              Webangebot als auch seine Werbung zu optimieren. Sofern eine
              entsprechende Einwilligung abgefragt wurde (z.&nbsp;B. eine
              Einwilligung zur Speicherung von Cookies), erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              <a
                href="https://privacy.google.com/businesses/controllerterms/mccs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://privacy.google.com/businesses/controllerterms/mccs/
              </a>
              .
            </p>
            <h4>IP Anonymisierung</h4>
            <p>
              Wir haben auf dieser Website die Funktion IP-Anonymisierung
              aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
              Mitgliedstaaten der Europäischen Union oder in anderen
              Vertragsstaaten des Abkommens über den Europäischen
              Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in
              Ausnahmefällen wird die volle IP-Adresse an einen Server von
              Google in den USA übertragen und dort gekürzt. Im Auftrag des
              Betreibers dieser Website wird Google diese Informationen
              benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
              die Websiteaktivitäten zusammenzustellen und um weitere mit der
              Websitenutzung und der Internetnutzung verbundene Dienstleistungen
              gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von
              Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
              nicht mit anderen Daten von Google zusammengeführt.
            </p>
            <h4>Browser Plugin</h4>
            <p>
              Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google
              verhindern, indem Sie das unter dem folgenden Link verfügbare
              Browser-Plugin herunterladen und installieren:
              <a
                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              .
            </p>
            <p>
              Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
              finden Sie in der Datenschutzerklärung von Google:
              <a
                href="https://support.google.com/analytics/answer/6004245?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.google.com/analytics/answer/6004245?hl=de
              </a>
              .
            </p>
            <h4>Auftragsverarbeitung</h4>
            <p>
              Wir haben mit Google einen Vertrag zur Auftragsverarbeitung
              abgeschlossen und setzen die strengen Vorgaben der deutschen
              Datenschutzbehörden bei der Nutzung von Google Analytics
              vollständig um.
            </p>
            <h4>Speicherdauer</h4>
            <p>
              Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die
              mit Cookies, Nutzerkennungen (z.&nbsp;B. User ID) oder Werbe-IDs
              (z.&nbsp;B. DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind,
              werden nach 14 Monaten anonymisiert bzw. gelöscht. Details hierzu
              ersehen Sie unter folgendem Link:
              <a
                href="https://support.google.com/analytics/answer/7667196?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.google.com/analytics/answer/7667196?hl=de
              </a>
            </p>
          </div>
          <div class="w-embed">
            <h3>Google Ads</h3>
            <p>
              Der Websitebetreiber verwendet Google Ads. Google Ads ist ein
              Online-Werbeprogramm der Google Ireland Limited („Google“), Gordon
              House, Barrow Street, Dublin 4, Irland.
            </p>
            <p>
              Google Ads ermöglicht es uns Werbeanzeigen in der
              Google-Suchmaschine oder auf Drittwebseiten auszuspielen, wenn der
              Nutzer bestimmte Suchbegriffe bei Google eingibt
              (Keyword-Targeting). Ferner können zielgerichtete Werbeanzeigen
              anhand der bei Google vorhandenen Nutzerdaten (z.&nbsp;B.
              Standortdaten und Interessen) ausgespielt werden
              (Zielgruppen-Targeting). Wir als Websitebetreiber können diese
              Daten quantitativ auswerten, indem wir beispielsweise analysieren,
              welche Suchbegriffe zur Ausspielung unserer Werbeanzeigen geführt
              haben und wie viele Anzeigen zu entsprechenden Klicks geführt
              haben.
            </p>
            <p>
              Die Nutzung von Google Ads erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an einer möglichst effektiven Vermarktung seiner Dienstleistung
              Produkte.
            </p>
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              <a
                href="https://policies.google.com/privacy/frameworks"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy/frameworks
              </a>{' '}
              und
              <a
                href="https://privacy.google.com/businesses/controllerterms/mccs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://privacy.google.com/businesses/controllerterms/mccs/
              </a>
              .
            </p>
            <h3>Google Conversion-Tracking</h3>
            <p>
              Diese Website nutzt Google Conversion Tracking. Anbieter ist die
              Google Ireland Limited („Google“), Gordon House, Barrow Street,
              Dublin 4, Irland.
            </p>
            <p>
              Mit Hilfe von Google-Conversion-Tracking können Google und wir
              erkennen, ob der Nutzer bestimmte Aktionen durchgeführt hat. So
              können wir beispielsweise auswerten, welche Buttons auf unserer
              Website wie häufig geklickt und welche Produkte besonders häufig
              angesehen oder gekauft wurden. Diese Informationen dienen dazu,
              Conversion-Statistiken zu erstellen. Wir erfahren die Gesamtanzahl
              der Nutzer, die auf unsere Anzeigen geklickt haben und welche
              Aktionen sie durchgeführt haben. Wir erhalten keine Informationen,
              mit denen wir den Nutzer persönlich identifizieren können. Google
              selbst nutzt zur Identifikation Cookies oder vergleichbare
              Wiedererkennungstechnologien.
            </p>
            <p>
              Die Nutzung von Google Conversion-Tracking erfolgt auf Grundlage
              von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
              berechtigtes Interesse an der Analyse des Nutzerverhaltens, um
              sowohl sein Webangebot als auch seine Werbung zu optimieren.
              Sofern eine entsprechende Einwilligung abgefragt wurde (z.&nbsp;B.
              eine Einwilligung zur Speicherung von Cookies), erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Mehr Informationen zu Google Conversion-Tracking finden Sie in den
              Datenschutzbestimmungen von Google:
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
